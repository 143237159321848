<template>
  <div class="w-full h-full px-3 py-5">
    <loader v-if="isLoading" size="xxs" :loader-image="false" />
    <template v-else>
      <div class="flex flex-col gap-8">
        <div class="flex justify-start items-center gap-8">
          <h1 class="text-xl text-left font-extrabold">Leave</h1>
          <breadcrumb :items="breadcrumbs" />
        </div>
        <div class="flex flex-col gap-0 my-5">
          <div class="shadow rounded">
            <tab
              :border="true"
              :tabs="tabs"
              :active-tab="currentTab"
              @currentTab="currentTab = $event"
            />
          </div>
          <annual-leave-plan v-if="currentTab === 'Leave Plan'" />
          <leave-request v-if="currentTab === 'Leave Request'" />
          <days-back-request v-if="currentTab === 'Days Back Request'" />
          <year-end-leave-balances v-if="currentTab === 'Year End Leave Balances'" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Loader from "@scelloo/cloudenly-ui/src/components/loader"
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb"
import Tab from "@scelloo/cloudenly-ui/src/components/tab";

export default {
  components: {
    Loader,
    Breadcrumb,
    Tab,

    AnnualLeavePlan: () => import("./AnnualLeavePlan"),
    LeaveRequest: () => import("./LeaveRequest"),
    DaysBackRequest: () => import("./DaysBackRequest"),
    YearEndLeaveBalances: () => import("./YearEndLeaveBalances"),
  },
  computed: {
    breadcrumbs(){
      return [
        { disabled: false, text: "Leave", href: "Leave", id: "Leave" },
        { disabled: false, text: this.currentTab, href: this.currentTab, id: this.currentTab },
      ]
    }
  },
  data() {
    return {
      isLoading: false,
      currentTab: "Leave Plan",
      tabs: [
        'Leave Plan',
        'Leave Request',
        'Days Back Request',
        'Year End Leave Balances'
      ],
    };
  },
};
</script>
